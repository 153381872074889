<template>
  <div>
    <Header navName=""></Header>
    <!-- PC端 -->
    <div class="oa_PC" v-if="!$isAndroid">
      <div class="banner">
        <div class="container">
          <div class="banner-text">
            <h1>极砚OA办公系统</h1>
            <h2>企业数字化、信息化办公的优秀解决方案</h2>
            <div>
              <a href="https://oademo.gdoeda.com/" class="btn-down" target="_blank"><span>体验演示</span></a>
              <!-- <a href="/anli/30.html" class="btn-go" target="_blank"><span>查看价格</span></a> -->
            </div>
          </div>
          <div class="banner-img">
            <img src="@/static/oa/banner_bg1.png" alt="">
          </div>
        </div>
      </div>

      <div class="advantage">
        <div class="container">
          <header class="section-header">
            <h2>企业管理九大难题，极砚OA完美解决</h2>
            <p>实现数字化办公，优化调整管理组织结构体制，在提高效率的基础上，提升协同办公能力，强化决策的一致性。</p>
          </header>
          <div class="item-wrap">
            <div class="item-col-3" v-for="(item, index) in advantageList" :key="index">
              <div class="box">
                <h3>{{ item.h }}<span>{{ item.s }}</span></h3>
                <p>{{ item.p }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="check">
        <div class="container">
          <header class="section-header">
            <h2>多类型流程审批模式</h2>
            <p>固定流程、半固定流程、自由流程，满足多业务、多组织需求，企业OA流程审批更灵活、更高效。</p>
          </header>
          <ul class="item-wrap">
            <li v-for="(item, index) in checkList" :key="index">
              <div class="li">
                <img :src="require(`@/static/oa/${item.icon}`)" alt="">
                <p>{{ item.name }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="services">
        <div class="container">
          <header class="section-header">
            <h2>六大主要功能模块，开箱即用</h2>
            <p>为企业构建一站式智能、敏捷、高效的数字化及信息化办公平台。</p>
          </header>
          <div class="item-wrap">
            <div class="item-col-3" v-for="(item, index) in servicesList" :key="index">
              <div :class="['service-box', item.boxI]">
                <i :class="['bi', item.icon]"></i>
                <h3>{{ item.h }}</h3>
                <p>{{ item.p }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="step">
        <div class="step-container">
          <div class="container">
            <header class="section-header">
              <h2>系统私有化部署的优势</h2>
            </header>
            <div class="item-wrap">
              <div class="item-col-6" v-for="(item, index) in stepList" :key="index">
                <div :class="['step-box', item.color]">
                  <i :class="['bi', item.icon]"></i>
                  <div>
                    <p>{{ item.p }}</p>
                    <span>{{ item.s }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <div class="oa_H5" v-else>
      <div class="tit-box">
        <div class="tit">极砚OA办公系统</div>
        <div class="text">企业数字化、信息化办公的优秀解决方案</div>
        <img src="@/static/oa/banner_bg1.png" alt="" style="width:85%;margin-bottom:0.3rem;">
        <a href="https://oademo.gdoeda.com/" class="link-btn" target="_blank">体验演示</a>
        <div class="tit" style="padding-top:0rem;">
          企业管理八大难题<br>极砚OA完美解决
        </div>
        <div class="flex">
          <div class="flex-item" v-for="(item, index) in boxList" :key="index">
            <img :src="require(`@/static/oa/${item.icon}`)" alt="" class="cu-item-p">
            <div class="sk-tit">{{ item.tit }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>

      <div class="cu-gary-bg">
        <div class="tit">多类型流程审批模式</div>
        <div class="nsk-t">固定流程、半固定流程、自由流程<br>满足多业务、多组织，审批灵活、更高效</div>
        <div class="icons">
          <div class="icons-item progress" v-for="(item, index) in iconsList" :key="index">
            <img :src="require(`@/static/oa/${item.icon}`)" :alt="item.name" class="icons-pic">
            <div class="icons-txt">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <div class="blue-box">
        <img src="@/static/oa/bluepic1.png" alt="" class="bluepic">
        <div class="tit">极砚OA办公系统</div>
        <div class="nsk-t" style="color:#fff">
          企业数字化、客户供应商管理<br>企业信息化、办公审批、日常办公
        </div>
        <!-- <div class="link-btn">查看价格</div> -->
      </div>

      <div class="cu-bg">
        <div class="tit tit-04">六大主要功能模块，开箱即用</div>
        <div class="text">为企业构建智能、敏捷<br>高效的数字化及信息化办公平台</div>
        <div class="flex">
          <div class="flex-item" v-for="(item, index) in cuList" :key="index">
            <img :src="require(`@/static/oa/${item.icon}`)" alt="" class="cu-item-p">
            <div class="tit">{{ item.tit }}</div>
          </div>
        </div>
      </div>

      <div class="base-gary-bg">
        <div class="tit">私有化部署优势</div>
        <div class="flex pd-02rem">
          <div class="flex-item" v-for="(item, index) in baseList" :key="index">
            <img :src="require(`@/static/oa/${item.icon}`)" alt="" class="cu-item-p" style="width:0.8rem;">
            <div class="sk-tit">{{ item.tit }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>

      <div class="kai-bg">
        <div class="tit">系统安全</div>
        <div class="text">多维度的系统安全防范，是您项目稳定运行的基础</div>
        <img src="@/static/oa/xtaq.png" alt="" style="width:100%;">
      </div>

      <div class="blue-box">
        <div class="tit">对于我们的产品有疑问？</div>
        <div class="text">您可以随时联系我们，我们将随时准备为您提供帮助</div>
        <a href="tel:15217695457">
          <div class="red-btn">15217695457</div>
        </a>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'oa',
  data() {
    return {
      boxList: [
        {
          icon: 'kai1.png',
          tit: '业务办理效率低',
          text: '办事跨部门沟通、找人签字，程序多、周期长，效率低',
        },
        {
          icon: 'kai2.png',
          tit: '内部沟通协作难',
          text: '各部门沟通费时费力，不知道负责人是谁，沟通协作难。',
        },
        {
          icon: 'kai3.png',
          tit: '规章制度落实难',
          text: '现有规章制度无法督促落实，不保证每项业务合规审批。',
        },
        {
          icon: 'kai4.png',
          tit: '签字盖章效率低',
          text: '签字盖章停留在面签，负责人不在，业务就推进不了。',
        },
        {
          icon: 'kai5.png',
          tit: '分/子公司管控难',
          text: '分/子公司分布在各地，业务运营、制度落实无法管控。',
        },
        {
          icon: 'kai6.png',
          tit: '责任追踪查询难',
          text: '办事过程不透明、无痕迹，缺乏可信记录，追责难。',
        },
        {
          icon: 'kai7.png',
          tit: '内部知识流失快',
          text: '存个人文件，收集不及时、流失快、需要时找不到。',
        },
        {
          icon: 'kai8.png',
          tit: '印章管理风险高',
          text: '实体印章管理中存在盗用、乱用、丢失等风险。',
        },
      ],
      iconsList: [
        {
          icon: 'email3.png',
          name: '请假审批',
        },
        {
          icon: 'customer2.png',
          name: '出差申请',
        },
        {
          icon: 'customer3.png',
          name: '加班申请',
        },
        {
          icon: 'customer7.png',
          name: '公文流转',
        },
        {
          icon: 'customer8.png',
          name: '会议室预定',
        },
        {
          icon: 'customer12.png',
          name: '用章申请',
        },
        {
          icon: 'customer10.png',
          name: '用车申请',
        },
        {
          icon: 'customer11.png',
          name: '付款申请',
        },
        {
          icon: 'customer9.png',
          name: '奖励申请',
        },
        {
          icon: 'customer4.png',
          name: '采购申请',
        },
        {
          icon: 'customer5.png',
          name: '活动经费',
        },
        {
          icon: 'customer6.png',
          name: '物料申领',
        },
        {
          icon: 'email1.png',
          name: '招聘申请',
        },
        {
          icon: 'email2.png',
          name: '入职申请',
        },
        {
          icon: 'customer1.png',
          name: '转正申请',
        },
        {
          icon: 'customer13.png',
          name: '离职申请',
        },
        {
          icon: 'customer14.png',
          name: '费用报销',
        },
        {
          icon: 'customer15.png',
          name: '发票审批',
        },
      ],
      cuList: [
        {
          icon: 'dan1.png',
          tit: '办公审批',
        },
        {
          icon: 'dan2.png',
          tit: '日常办公',
        },
        {
          icon: 'dan5.png',
          tit: '客户管理',
        },
        {
          icon: 'dan6.png',
          tit: '合同管理',
        },
        {
          icon: 'dan10.png',
          tit: '项目管理',
        },
        {
          icon: 'dan8.png',
          tit: '财务管理',
        },
      ],
      baseList: [
        {
          icon: 'kai9.png',
          tit: '功能应用',
          text: '更灵活',
        },
        {
          icon: 'kai10.png',
          tit: '功能定制',
          text: '更贴近',
        },
        {
          icon: 'kai11.png',
          tit: '可拓展性',
          text: '更强',
        },
        {
          icon: 'kai12.png',
          tit: '数据私密性',
          text: '更强',
        },
      ],
      advantageList: [
        {
          h: '一、业务办理效率',
          s: '低',
          p: '办事跨部门咨询、沟通、找人签字，程序多、周期长，效率难保障',
        },
        {
          h: '二、内部沟通协作',
          s: '难',
          p: '各部门沟通费时费力，具体事项不知道负责人是谁，沟通协作难。',
        },
        {
          h: '三、规章制度落实',
          s: '难',
          p: '现有规章制度无法督促落实，不能确保每项业务经过合规审批授权。',
        },
        {
          h: '四、签字盖章效率',
          s: '低',
          p: '签字盖章停留在面签、手动操作阶段，负责人不在，业务就推进不了。',
        },
        {
          h: '五、分/子公司管控',
          s: '难',
          p: '分/子公司分布在各地，业务运营、制度落实情况看不见，无法管控。',
        },
        {
          h: '六、责任追踪查询',
          s: '难',
          p: '办事过程不透明、无痕迹， 申请-审批缺乏可信记录，追责难。',
        },
        {
          h: '七、内部知识流失',
          s: '快',
          p: '各类知识存在个人电脑里，收集不及时-流失快-需要用的时候找不到。',
        },
        {
          h: '八、印章管理风险',
          s: '高',
          p: '实体印章管理中存在盗用-冒用-乱用-丢失等风险，使用效率低、成本高。',
        },
        {
          h: '九、流程、信息流通',
          s: '慢',
          p: '数据、流程等信息不能实时同步和有效共享，甚至出现“信息孤岛”现象。',
        },
      ],
      checkList: [
        {
          icon: 'qingjia.png',
          name: '请假审批',
        },
        {
          icon: 'chuchai.png',
          name: '出差申请',
        },
        {
          icon: 'waichu.png',
          name: '外出申请',
        },
        {
          icon: 'jiaban.png',
          name: '加班申请',
        },
        {
          icon: 'huiyi.png',
          name: '会议室预定',
        },
        {
          icon: 'gongwen.png',
          name: '公文流转',
        },
        {
          icon: 'weixiu.png',
          name: '物品维修',
        },
        {
          icon: 'zhang.png',
          name: '用章申请',
        },
        {
          icon: 'che.png',
          name: '用车申请',
        },
        {
          icon: 'guihuai.png',
          name: '用车归还',
        },
        {
          icon: 'jiekuan.png',
          name: '借款申请',
        },
        {
          icon: 'fukuan.png',
          name: '付款申请',
        },
        {
          icon: 'jiangli.png',
          name: '奖励申请',
        },
        {
          icon: 'caigou.png',
          name: '采购申请',
        },
        {
          icon: 'huodong.png',
          name: '活动经费',
        },
        {
          icon: 'wuliao.png',
          name: '物料申领',
        },
        {
          icon: 'zhaopin.png',
          name: '招聘申请',
        },
        {
          icon: 'ruzhi.png',
          name: '入职申请',
        },
        {
          icon: 'zhuanzheng.png',
          name: '转正申请',
        },
        {
          icon: 'zhuangang.png',
          name: '转岗申请',
        },
        {
          icon: 'lizhi.png',
          name: '离职申请',
        },
        {
          icon: 'baoxiao.png',
          name: '费用报销',
        },
        {
          icon: 'fapiao.png',
          name: '发票审批',
        },
        {
          icon: 'diy.png',
          name: '万能审批',
        },
      ],
      servicesList: [
        {
          boxI: 'blue',
          icon: 'ri-briefcase-2-line',
          h: '办公审批',
          p: '支持人事、财务、行政、业务等多审批流程',
        },
        {
          boxI: 'orange',
          icon: 'ri-calendar-event-line',
          h: '日常办公',
          p: '日程、计划、周报、日报等信息化办公工具',
        },
        {
          boxI: 'green',
          icon: 'ri-contacts-book-line',
          h: '客户管理',
          p: '统一管理客户，沉淀客户资产，避免客户流失',
        },
        {
          boxI: 'red',
          icon: 'ri-article-line',
          h: '合同管理',
          p: '合同维护、审批、执行、变更、关闭全流程管理',
        },
        {
          boxI: 'purple',
          icon: 'ri-folders-line',
          h: '项目管理',
          p: '项目操作记录全覆盖跟踪，项目进度一目了然',
        },
        {
          boxI: 'pink',
          icon: 'ri-money-cny-box-line',
          h: '财务管理',
          p: '财务报销、开票、到账，财务数据规范化管理',
        },
      ],
      stepList: [
        {
          color: 'blue',
          icon: 'ri-dashboard-line',
          p: '功能应用',
          s: '更灵活',
        },
        {
          color: 'orange',
          icon: 'ri-shield-check-line',
          p: '安 全 性',
          s: '更高',
        },
        {
          color: 'green',
          icon: 'ri-notification-badge-line',
          p: '可拓展性',
          s: '更强',
        },
        {
          color: 'red',
          icon: 'ri-money-cny-circle-line',
          p: '性 价 比',
          s: '更高',
        },
        {
          color: 'purple',
          icon: 'ri-lock-2-line',
          p: '数据私密',
          s: '更强',
        },
        {
          color: 'pink',
          icon: 'ri-checkbox-multiple-blank-line',
          p: '功能定制',
          s: '更贴近',
        },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang='less'>
.oa_PC {
  .section-header {
    padding-bottom: 36px;

    h2 {
      font-size: 32px;
      font-weight: 500;
      text-align: center;
    }

    p {
      font-size: 16px;
      padding: 24px 0;
      line-height: 1.6;
      text-align: center;
      color: #646464;
    }
  }

  .banner {
    width: 100%;
    height: 80vh;
    background: url("~@/static/oa/bg1.png") bottom center no-repeat;
    background-size: cover;
    border-bottom: 1px solid #f6f9ff;

    .container {
      width: 1220px;
      height: 100%;
      margin: 0 auto;
      position: relative;

      .banner-text {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -120px;

        h1 {
          margin: 0;
          font-size: 48px;
          font-weight: 600;
          color: #323232;
        }

        h2 {
          color: #646464;
          margin: 20px 0 0 0;
          font-size: 22px;
          font-weight: 100;
        }

        .btn-down {
          margin: 60px 0 0 0;
          display: inline-block;
          padding: 12px 24px;
          border-radius: 3px;
          transition: 0.5s;
          color: #fff;
          border: 1px solid #186AF2;
          background: #186AF2;
          box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
          font-size: 16px;
          font-weight: 600;

          &:hover {
            opacity: 0.8
          }
        }

        .btn-go {
          margin: 60px 0 0 20px;
          display: inline-block;
          padding: 12px 24px;
          border-radius: 3px;
          transition: 0.5s;
          color: #186AF2;
          border: 1px solid #186AF2;
          box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
          font-size: 16px;
          font-weight: 600;

          &:hover {
            opacity: 0.8
          }
        }
      }

      .banner-img {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -240px;

        img {
          width: 666px;
        }
      }
    }
  }

  .advantage {
    width: 100%;
    padding: 60px 0;
    background-color: #fff;

    .container {
      width: 1220px;
      height: 100%;
      margin: 0 auto;
      position: relative;

      .item-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;

        .item-col-3 {
          width: 33.3333333333%;
          padding: 0 12px;
          box-sizing: border-box;

          .box {
            padding: 24px;
            border-radius: 6px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 1.6;
            background-color: #fff;
            box-shadow: 0px 0 6px rgba(1, 41, 112, 0.1);
            height: 100%;

            h3 {
              font-size: 20px;
              color: #012970;
              font-weight: 600;
              margin-bottom: 12px;

              span {
                color: #EA4335;
              }
            }
          }
        }
      }
    }
  }

  .check {
    padding: 60px 0;
    background: #F9FBFE url(~@/static/oa/home_bg.png) top center no-repeat;

    .container {
      ul {
        width: 1220px;
        margin: 0 auto;

        &.item-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
        }

        li {
          text-align: center;
          width: 12.5%;
          padding: 0 12px;
          box-sizing: border-box;

          .li {
            padding: 16px;
            border-radius: 6px;
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 1.6;
            background-color: #fff;
            box-shadow: 0px 0 6px rgba(1, 41, 112, 0.1);
            height: 100%;
            color: #20242b;

            &:hover {
              box-shadow: 0px 0 20px rgba(1, 41, 112, .16);
              color: #012970;

              &:hover img {
                transform: scale(1.1);
              }
            }
          }

          img {
            height: 50px;
            width: auto;
            margin: 0 auto 12px;
            transition: 0.4s;
            transform: scale(1);
          }

          p {
            font-size: 16px;
            line-height: 1.6;
          }
        }
      }

    }
  }

  .services {
    width: 100%;
    text-align: center;
    padding: 60px 0;
    background-color: #ffffff;

    .container {
      width: 1220px;
      height: 100%;
      margin: 0 auto;
      position: relative;

      .item-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;

        .item-col-3 {
          width: 33.3333333333%;
          padding: 0 12px;
          box-sizing: border-box;

          .service-box {
            box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
            height: 100%;
            background-color: #fff;
            margin-bottom: 30px;
            padding: 30px 12px;
            text-align: center;
            transition: 0.3s;
            border-radius: 6px;

            .bi {
              font-size: 48px;
              padding: 36px 12px;
              position: relative;
              margin-bottom: 24px;
              border-radius: 4px;
              display: inline-block;
              line-height: 0;
              transition: 0.3s;
            }

            h3 {
              color: #323232;
              margin-bottom: 20px;
              font-weight: 400;
              font-size: 24px;
            }

            p {
              color: #646464;
              font-size: 13px;
            }

            &:hover {
              color: #fff;

              h3,
              p {
                color: #fff;
              }

              .bi {
                background: #fff;
              }
            }

            &.blue {
              border-bottom: 3px solid #2db6fa;

              .bi {
                color: #2db6fa;
                background: #dbf3fe;
              }

              &:hover {
                background: #2db6fa;
              }
            }

            &.orange {
              border-bottom: 3px solid #f68c09;

              .bi {
                color: #f68c09;
                background: #fde3c4;
              }

              &:hover {
                background: #f68c09;
              }
            }

            &.green {
              border-bottom: 3px solid #52CD75;

              .bi {
                color: #52CD75;
                background: #cffddf;
              }

              &:hover {
                background: #52CD75;
              }
            }

            &.red {
              border-bottom: 3px solid #EA4335;

              .bi {
                color: #EA4335;
                background: #fef7f8;
              }

              &:hover {
                background: #EA4335;
              }
            }

            &.purple {
              border-bottom: 3px solid #9B74D7;

              .bi {
                color: #9B74D7;
                background: #f8e4fd;
              }

              &:hover {
                background: #9B74D7;
              }
            }

            &.pink {
              border-bottom: 3px solid #f51f9c;

              .bi {
                color: #f51f9c;
                background: #feecf7;
              }

              &:hover {
                background: #f51f9c;
              }
            }

          }
        }
      }
    }
  }

  .step {
    padding-top: 120px;
    background: #fff url(~@/static/oa/step.png) top center no-repeat;

    .step-container {
      width: 1220px;
      margin: 0 auto;
      background-color: #F7FAFF;
      padding-bottom: 60px;

      .item-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;

        .item-col-6 {
          width: 16.6666666666%;
          padding: 0 12px;
          box-sizing: border-box;

          .step-box {
            display: flex;
            align-items: center;
            padding: 20px 16px;
            background: #fff;
            box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
            border-radius: 6px;

            i {
              font-size: 48px;
              line-height: 0;
              margin-right: 12px;
            }

            p {
              font-size: 16px;
              line-height: 22px;
            }

            span {
              font-size: 22px;
              display: inline-block;
              font-weight: 500;
            }

            &.blue{
              i,span{
                color: #4154f1;
              }
            }

            &.orange{
              i,span{
                color: #ee6c20;
              }
            }

            &.green{
              i,span{
                color: #52CD75;
              }
            }

            &.red{
              i,span{
                color: #EA4335;
              }
            }

            &.purple{
              i,span{
                color: #9B74D7;
              }
            }

            &.pink{
              i,span{
                color: #f51f9c;
              }
            }
          }
        }
      }
    }
  }




}

.oa_H5 {
  .tit {
    padding-top: 1.4rem;
    text-align: center;
    font-size: 0.56rem;
    margin-bottom: 0.49rem;
    font-weight: 800;
  }

  .flex {
    display: flex;
    justify-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
  }


  .flex-item {
    width: 50%;
    max-width: 50%;
    text-align: center;
    flex-direction: column;
  }

  .tit-box {
    text-align: center;
    padding: 0.42rem;
    background: #FFF;

    .text {
      font-size: 0.35rem;
      color: #333;
      margin-bottom: 0.7rem;
    }

    .link-btn {
      width: 5.6rem;
      height: 1.12rem;
      color: #fff;
      border-radius: 2.1rem;
      line-height: 1.12rem;
      margin: 0 auto;
      font-size: 0.42rem;
      text-align: center;
      margin-bottom: 1.4rem;
      background-color: rgb(51, 94, 255);
      display: block;
    }

    .cu-item-p {
      width: 1.12rem;
      margin: 0 auto;
      display: block;
    }

    .sk-tit {
      font-size: 0.42rem;
      font-weight: 800;
      margin-bottom: 0.28rem;
      text-align: center;
    }

  }

  .cu-gary-bg {
    text-align: center;
    background-color: #F5F6F7;
    overflow: hidden;
    padding-bottom: 0.98rem;
    padding: 0.14rem;

    .nsk-t {
      font-size: 0.42rem;
      color: #333;
      margin-bottom: 0.7rem;
    }

    .icons {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .icons-item {
        width: calc(100%/3);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 20px;

        .icons-pic {
          width: 1.12rem;
          height: 1.12rem;
          display: block;
        }

        .icons-txt {
          font-size: 0.42rem;
        }
      }
    }

  }

  .blue-box {
    background: url(~@/static/oa/bluebg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-x: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 0.42rem;
    overflow: hidden;

    .bluepic {
      width: 70%;
      text-align: center;
    }

    .tit {
      color: #FFF;
      padding-top: 0.2rem;
    }

    .nsk-t {
      font-size: 0.42rem;
      color: #FFF;
      margin-bottom: 0.7rem;
    }

    .link-btn {
      width: 5.6rem;
      height: 0.98rem;
      border-radius: 2.1rem;
      line-height: 0.98rem;
      margin: 0 auto;
      font-size: 0.42rem;
      text-align: center;
      margin-bottom: 1.4rem;
      background-color: #fff;
      color: #4f8eff;
    }
  }

  .cu-bg {
    background: #FFF;
    text-align: center;

    .tit-04 {
      font-size: 0.56rem;
      font-weight: 800;
      padding-top: 1.4rem;
    }

    .text {
      font-size: 0.35rem;
      color: #333;
      margin-bottom: 0.7rem;
    }

    .flex-item {
      border-radius: 0.14rem;
      border: 0.01rem solid #e6e6e6;
      width: 45%;
      margin-bottom: 0.42rem;
      font-size: 0;

      .cu-item-p {
        padding-top: 0.28rem;
        width: 35%;
      }

      .tit {
        padding-top: 0.3rem;
        text-align: center;
        font-size: 0.56rem;
        margin-bottom: 0.49rem;
        font-weight: 800;
      }
    }
  }

  .base-gary-bg {
    text-align: center;
    background-color: #F5F6F7;
    overflow: hidden;
    padding-bottom: 0.7rem;
    padding: 0.1rem;

    .cu-item-p {
      width: 1.12rem;
    }

    .sk-tit {
      font-size: 0.42rem;
      font-weight: 800;
      margin-bottom: 0.28rem;
      text-align: center;
    }

    .text {
      font-size: 0.4rem;
      color: #333;
      margin-bottom: 0.7rem;
    }
  }

  .kai-bg {
    background: url(~@/static/oa/call-bannner-bg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-x: center;
    margin: 0 auto;
    text-align: center;
    padding: 0 0.49rem;
    overflow: hidden;

    .text {
      font-size: 0.4rem;
      color: #333;
      margin-bottom: 0.7rem;
    }
  }

  .blue-box {
    background: url(~@/static/oa/bluebg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-x: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 0.42rem;
    overflow: hidden;

    .tit {
      color: #fff;
      padding-top: 0.42rem;
    }

    .text {
      font-size: 0.4rem;
      color: #FFF;
      margin-bottom: 0.7rem;
    }

    .red-btn {
      width: 5.6rem;
      height: 1.12rem;
      background-color: #FFF;
      color: #4F8EFF;
      border-radius: 2.1rem;
      line-height: 1.12rem;
      margin: 0 auto;
      font-size: 0.45rem;
      text-align: center;
      margin-bottom: 1.4rem;
      font-weight: bold;
    }
  }

}
</style>